import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {flatEntity} from "../../toolbox/query";
import {genderToString, 비속어체크} from "../../toolbox/format";
import {FormComponent, HelpBtn} from "../components/FormComponent";
import {loadingAction} from "../../redux/loadingReducer";
import {GET_ME, GET_USER, UPDATE_USER} from "../../query/userQuery";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {find, isEmpty} from "lodash";
import {Comment} from "../components/Comment";
import {useForm} from "../../toolbox/hook";
import {CREATE_COMMENT, DELETE_COMMENT} from "../../query/mutationQuery";
import {getLoginLink} from "../../toolbox/kakao";
import moment from "moment-timezone";
import {calcTimeDiffTxt} from "../../toolbox/time";


const Profile = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  width: 98%;
  max-width: 579px;

  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
      margin-top: 42px;
    }
  }

  .profile-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .nickname {
    font-size: 2em;
    margin: 10px 0;
    color: #4a4a4a;
  }

  .gender {
    font-size: 1.2em;
    color: #777;
  }

  .edit-profile-button {
    position: absolute;
    top: 0;
    right: 10px;
    //display: inline-block;
    margin-top: 10px;
    padding: 6px 10px;
    background: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    @media only screen and (min-width: 768px) {
      padding: 10px 20px;
    }
  }

  .edit-profile-button:hover {
    background: #2980b9;
  }

  .profile-info {
    text-align: left;
  }

  .profile-info h3 {
    font-size: 1.5em;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  .profile-info ul {
    list-style: none;
    padding: 0;
  }

  .profile-info li {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #f3f3f3;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap;
  }

  .profile-info li.column {
    word-break: break-all;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    background: #f3f3f3;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: pre-wrap;
  }
`
const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  //font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 32px;
  color: #333;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //height: 100vh;

  .description {
    font-size: 0.8em;
    word-break: keep-all;
    padding: 0 10px 20px;
  }

  .comments-section {
    margin-top: 40px;
    text-align: left;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 98%;
    max-width: 579px;
  }

  .comments-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
  }

  .comment-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .comment-submit {
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .comment-submit:hover {
    background: #2980b9;
  }
`
export const ProfileDetailPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const myUserInfo = useSelector(({user}) => user.user);
    const userId = params?.id;
    const [userInfo, setUserInfo] = useState(null);
    const [profileData, setProfileData] = useState(null);

    const [activeTab, setActiveTab] = useState('public');
    const [bookmarked, setBookmarked] = useState(null);
    const [commentList, setCommentList] = useState(null);

    const {form: commentForm, onChange} = useForm({
        body: '',
    });

    const [createComment] = useMutation(CREATE_COMMENT);
    const [updateUser] = useMutation(UPDATE_USER);
    const {data: queryData, refetch} = useQuery(GET_USER, {
        fetchPolicy: "cache-first",
        skip: userId === undefined,
        variables: {id: userId}
    });

    const isMy = (userId) => {
        if (userId === tokenInfo?.id) {
            return true;
        }
        return false;
    }

    const onClickComment = () => {
        let body = commentForm.body;
        if (isEmpty(tokenInfo)) {
            alert('로그인이 필요합니다.');
            return;
        }
        if (비속어체크(body)) {
            alert('부적절한 단어가 포함되어있습니다.');
            return;
        }

        dispatch(loadingAction.loading(true));
        createComment({
            variables: {
                data: {
                    body: body,
                    user: tokenInfo.id,
                    target: userId,
                },
            },
            onCompleted: data => {
                dispatch(loadingAction.loading(false));
                onChange({id: 'body', value: ''});
                refetch();
            }
        });
    }

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getProfile', {
            userId: userId,
        }).then((res) => {
            if (res.data) {
                setProfileData(res.data);
            }
        });
    }, [bookmarked, userId]);


    useEffect(() => {
        if (queryData?.usersPermissionsUser?.data) {
            const payload = flatEntity(queryData.usersPermissionsUser.data);
            setCommentList(payload.profileComments);
        }
    }, [queryData]);

    useEffect(() => {
        if (isMy(userId)) {
            if (myUserInfo && profileData) {
                setUserInfo({
                    ...profileData,
                    ...myUserInfo,
                });
            }
        } else {
            if (profileData) {
                setUserInfo(profileData);
            }
        }
    }, [tokenInfo, userId, myUserInfo, profileData]);

    useEffect(() => {
        if (myUserInfo) {
            setBookmarked(!!find(myUserInfo.bookmarkSends, (b) => b.receiver.id === userId));
        }
    }, [myUserInfo]);

    const toggleBookmark = async () => {
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to, {replace: true});
            return;
        }

        dispatch(loadingAction.loading(true));
        const res = await axios.post(SERVER_ADDRESS + '/api/party-members/bookmark', {
            bookmarkId: Number(userId),
        });

        if (res.data) {
            window.fbq('track', 'AddToCart');
        }

        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {}
            }
        };
        await updateUser(payload);
        setBookmarked(res.data);
        dispatch(loadingAction.loading(false));
    }

    if (!userInfo) {
        return <div>

        </div>
    }

    return <PageWrap>
        {isMy(userId) && <TabContainer>
            <Tab isActive={activeTab === 'public'} onClick={() => setActiveTab('public')}>
                전체 공개 프로필
            </Tab>
            <Tab isActive={activeTab === 'private'} onClick={() =>  setActiveTab('private')}>
                비공개 프로필
            </Tab>
        </TabContainer>}

        {isMy(userId) && activeTab === 'private' && <div className="description">
            이 프로필은 모임 종료 후, 내가 ‘즐거웠습니다’ 이상의 평가를 한 참석자들에게만 보이는 시크릿 프로필입니다.<br/>
            연락처나 SNS 등을 적어서 맘에 드는 참석자들과 모임 후 연락을 이어가보세요.
        </div>}
        <Profile>
            <div className="profile-header">
                {activeTab === 'private'? <>
                    <img src={userInfo.profileImagePrivate?.url ?? "/defaultProfile2.png"} alt="프로필 사진" className="profile-img" />
                </>: <>
                    <img src={userInfo.profileImage?.url ?? "/defaultProfile2.png"} alt="프로필 사진" className="profile-img" />
                </>}
                <h2 className="nickname">{userInfo.nickname}</h2>
            </div>
            <div className="profile-info">
                <ul>
                    {isMy(userId) && activeTab === 'private'?
                        <li className="column"><strong>소개<br/></strong>{userInfo.introducePrivate}</li>:
                        <li className="column"><strong>소개<br/></strong>{userInfo.introduce}</li>}
                    {/*<li><strong>성별:</strong> {genderToString(userInfo.gender)}</li>*/}
                </ul>
            </div>
            {isMy(userId) && <Link to={`/profile_edit?type=${activeTab}`}>
                <span className="edit-profile-button">수정하기</span>
            </Link>}
            {!isMy(userId) &&
                <span className="edit-profile-button" onClick={toggleBookmark}>
                    {bookmarked? '북마크 취소하기': '북마크하기'} <HelpBtn contents={"상대방은 내 북마크를 알 수 없습니다.\n" +
                    "북마크된 상대방과는 매칭이 될 확률이 높아집니다."} white={true} />
                </span>}
        </Profile>
        <Profile style={{marginTop: '20px'}}>
            <div className="profile-info">
                <ul>
                    <li><strong>등급:</strong> {userInfo.grade ?? '예비 게스트'}<HelpBtn contents={"예비 게스트: 막 가입한 게스트\n" +
                        "정식 게스트: 모임에 3회 이상 참석한 게스트"} title="게스트 등급 안내" white={false} /></li>
                    <li><strong>매너온도:</strong> {36.5}도</li>
                    <li><strong>받은 북마크:</strong> {userInfo.bookmarkCount}회</li>
                    <li><strong>최근 북마크 받음:</strong> {userInfo.recentBookmark? calcTimeDiffTxt(userInfo.recentBookmark): '없음'}</li>
                    {userInfo.score && <li><strong>나에 대한 예상 호감도:</strong> {userInfo.score}점 <HelpBtn contents={"상대방이 나에게 느낄 예상 호감도를 점수로 표시한 값입니다.\n우디의 ai가 다양한 요소를 고려하여 점수를 계산합니다."} /></li>}
                </ul>
            </div>
        </Profile>

        <div className="comments-section">
            <h3>방명록 {commentList ? commentList.length: 0}개</h3>
            <div className="comments-list">
                {commentList && commentList.map((c, index) => (
                    <Comment comment={c} canDelete={isMy(userId)} onDelete={() => refetch()} />
                ))}
            </div>
            <div className="comment-form">
                <textarea className="comment-input" value={commentForm.body} onChange={(e) => onChange({id: 'body', value: e.target.value})} placeholder="방명록을 작성해보세요."></textarea>
                <button className="comment-submit" onClick={onClickComment}>댓글 작성</button>
            </div>
        </div>
    </PageWrap>
}

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  word-break: keep-all;
`;

const Tab = styled.button`
  background-color: ${({ isDisable, isActive }) => (isDisable? '#cccccc': isActive ? '#007bff' : '#fff')};
  color: ${({ isDisable, isActive }) => (isDisable? '#999999': isActive ? '#fff' : '#000')};
  border: ${({ isDisable }) => (isDisable? '#999999': '1px solid #007bff')};
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ isDisable, isActive }) => (isDisable? '': isActive ? '#0056b3' : '#e0e0e0')};
  }
`;
