import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {filter, find, isEmpty, sortBy} from "lodash";
import {getLoginLink} from "../../toolbox/kakao";
import {commaNum, genderToString} from "../../toolbox/format";
import moment from "moment-timezone";
import {authAction} from "../../redux/authReducer";
import {userAction} from "../../redux/userReducer";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {parseJwt} from "../../toolbox/jwt";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS, TOSS_SUB_CLIENT_KEY} from "../../index";
import {GET_PARTY_DETAIL, GET_RESTAURANTS} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {getUpcomingMeet, getNextMeetTimes} from "../../toolbox/time";
import {UserProfile} from "../components/UserProfile";
import {Party, PartyReq} from "../components/Party";
import {FormComponent, HelpBtn} from "../components/FormComponent";
import {InviteDialog} from "./InviteDialog";
import {getSubscription, getSubscriptionCoupon, getSubscriptionStartCoupon} from "../../toolbox/entity";
import {PrimaryButton} from "../components/CommonComponents";
import {loadTossPayments} from "@tosspayments/payment-sdk";
import {Dialog} from "@mui/material";
import {useForm} from "../../toolbox/hook";


// const RESTAURANTS = ["라브리크 서울", "아올 강남", "띠엘로", "중식당표량", "후추포인트 강남"]

export const MyPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [open, setOpen] = useState(false);

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            navigate(getLoginLink(location), {replace: true});
        }
    }, [tokenInfo]);

    let subscription = getSubscription(userInfo);
    let partyCount = 0;
    if (userInfo?.tickets) {
        partyCount = filter(userInfo.tickets, t => !t.member).length;
    }

    let freeCount = 0;
    if (userInfo?.partyMembers.length < 3) {
        freeCount = 3 - userInfo.partyMembers.length;
    }

    let rewardGauge = 0;
    if (userInfo?.rewardGauge) {
        rewardGauge = userInfo.rewardGauge / 100;
    }

    const handleRenew = async () => {
        let coupon = getSubscriptionStartCoupon(userInfo);
        if (coupon && window.confirm(`사용 가능한 구독 쿠폰이 있습니다. 사용하시겠습니까?\n(${coupon.name})`)) {
            dispatch(loadingAction.loading(true));
            const response = await axios.post(SERVER_ADDRESS + '/api/coupons/useCoupon', {
                couponId: coupon.id,
            });
            if (response.data.result === 'error') {
                alert(response.data.msg);
            } else if (response.data.result === 'success') {
                const payload = {
                    refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                    variables: {
                        id: tokenInfo.id,
                        data: {
                            // autoEnter: userInfo.autoEnter,
                        }
                    }
                };
                await updateUser(payload);
                alert('쿠폰이 정상적으로 사용되었습니다.');
            }
            dispatch(loadingAction.loading(false));
            return null;
        }

        if (subscription) {
            if (window.confirm(`${commaNum(subscription.monthlyCharge)}원을 결제하고 구독 기간을 30일 연장합니다.\n계속하시겠습니까?`)) {
                dispatch(loadingAction.loading(true));

                const response = await axios.post(SERVER_ADDRESS + '/api/subscriptions/startSubscription', {});
                if (response.data.result === 'error') {
                    alert(response.data.msg);
                } else if (response.data.result === 'success') {
                    const payload = {
                        refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                        variables: {
                            id: tokenInfo.id,
                            data: {
                                // autoEnter: userInfo.autoEnter,
                            }
                        }
                    };
                    await updateUser(payload);
                    alert('구독이 정상적으로 연장되었습니다.');
                }
                dispatch(loadingAction.loading(false));
            }
        } else {
            navigate('/subscription');
        }
    }


    const handleLogout = () => {
        dispatch(authAction.logout());
        dispatch(userAction.logout());
    }

    const handleCard = async () => {
        let prev = location.pathname + "?" + encodeURIComponent(location.search.substr(1)) + encodeURIComponent(location.hash);
        const tossPayments = await loadTossPayments(TOSS_SUB_CLIENT_KEY);
        let customerKey = String(tokenInfo.id);
        if (customerKey.length === 1) {
            customerKey = `0${customerKey}`;
        }
        await tossPayments.requestBillingAuth('카드', {
            customerKey,
            successUrl: window.location.origin + '/card_success' + `?returnUrl=${prev}`,
            failUrl: window.location.origin + '/card_fail',
        }).then((value) => {
            console.log(value);
        }).catch((reason => {
            console.log(reason);
        }));
    }

    const handleAutoRenew = async () => {
        dispatch(loadingAction.loading(true));
        await axios.post(SERVER_ADDRESS + '/api/subscriptions/changeAutoRenew', {
            subscriptionId: subscription.id,
            autoRenew: !subscription.autoRenew,
        });
        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {}
            }
        };
        await updateUser(payload);
        dispatch(loadingAction.loading(false));
    }

    const handleNotiToggle = async () => {
        dispatch(loadingAction.loading(true));
        await axios.post(SERVER_ADDRESS + '/api/party-members/toggleNotification', {
            notificationAllow: !userInfo?.notificationAllow,
        });
        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {}
            }
        };
        await updateUser(payload);
        dispatch(loadingAction.loading(false));
    }

    return (
        <Container>
            <h2>마이 페이지</h2>
            <MeetingsSection>
                <h3>회원 정보</h3>
                <MembershipInfo>
                    <span>휴대폰 번호 : {userInfo?.phone}</span>
                    <PrimaryButton onClick={() => setOpen(true)}>변경하기</PrimaryButton>
                </MembershipInfo>
                <MembershipInfo>
                    <span>참석신청 알림 : {userInfo?.notificationAllow ? '켜짐': '꺼짐'}</span>
                    <PrimaryButton onClick={handleNotiToggle}>{userInfo?.notificationAllow ? '끄기': '켜기'}</PrimaryButton>
                </MembershipInfo>
            </MeetingsSection>


            <MeetingsSection>
                <h3>구독 정보</h3>
                <MembershipInfo>
                    <span>현재 구독기간 : {subscription? `${subscription.startDate} ~ ${subscription.endDate}`: '구독 상태 아님'}</span>
                    {subscription && <PrimaryButton onClick={handleRenew}>연장하기</PrimaryButton>}
                    {!subscription && <PrimaryButton onClick={handleRenew}>구독하기</PrimaryButton>}
                </MembershipInfo>

                {/*{subscription && <MembershipInfo>*/}
                {/*    <span>구독 자동연장 : {subscription.autoRenew ? '켜짐': '꺼짐'}</span>*/}
                {/*    <PrimaryButton onClick={handleAutoRenew}>{subscription.autoRenew ? '끄기': '켜기'}</PrimaryButton>*/}
                {/*</MembershipInfo>}*/}

                {subscription && <MembershipInfo>
                    <span>구독 요금 : {commaNum(subscription.monthlyCharge)}원 / 30일</span>
                </MembershipInfo>}
                <MembershipInfo>
                    <span>보유한 참석권 : {partyCount + rewardGauge}장</span>
                </MembershipInfo>
                <MembershipInfo>
                    <span>참석 신청 가능 횟수 : {partyCount + freeCount + rewardGauge}회</span>
                </MembershipInfo>
                <MembershipInfo>
                    <span>결제 등록카드 : {userInfo?.card?.name ?? '없음'}</span>
                    {userInfo?.card && <PrimaryButton onClick={handleCard}>변경하기</PrimaryButton>}
                    {!userInfo?.card && <PrimaryButton onClick={handleCard}>등록하기</PrimaryButton>}
                </MembershipInfo>
            </MeetingsSection>
            <PhoneChangeDialog open={open} onClose={() => setOpen(false)}/>
            <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
        </Container>
    );
};

const PhoneChangeDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [updateUser] = useMutation(UPDATE_USER);
    const {form, onChange} = useForm({
        phone: '',
    });

    useEffect(() => {
        if (userInfo && userInfo.phone) {
            onChange({id: 'phone', value: userInfo.phone});
        }
    }, [userInfo]);

    const onChangePhone = async () => {
        if (!form.phone) {
            alert('연락처를 입력해주세요');
            return;
        }
        const payload = {
            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
            variables: {
                id: tokenInfo.id,
                data: {
                    phone: form.phone,
                }
            }
        };
        dispatch(loadingAction.loading(true));
        await updateUser(payload);
        dispatch(loadingAction.loading(false));
        onClose();
    }

    return <Dialog open={open} onClose={onClose}>
        <PhoneWrap>
            <FormComponent title="휴대폰 번호 변경" id="phone" form={form} onChange={onChange} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PrimaryButton onClick={onChangePhone}>변경하기</PrimaryButton>
            </div>
        </PhoneWrap>
    </Dialog>
}

const PhoneWrap = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Line = styled.div`
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`
// Styled-Components를 사용하여 스타일 정의
const Container = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #333;
  min-height: 100vh;
  gap: 20px;
`;

const MembershipInfo = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MeetingsSection = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 440px;
  
  > h3 {
    margin-bottom: 8px;
  }
`;

const LogoutButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; 

  &:hover {
    background-color: #c82333;
  }
`;
