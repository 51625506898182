import styled, {css} from "styled-components";
import {toPhoneFormat} from "../../toolbox/format";
import {openAddress} from "../../toolbox/logic";
import moment from "moment-timezone";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {loadingAction} from "../../redux/loadingReducer";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/client";
import {DELETE_COMMENT} from "../../query/mutationQuery";
import {Link} from "react-router-dom";

export const Comments = ({comments}) => {

}

export const Comment = ({comment, canDelete, onDelete}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [deleteComment] = useMutation(DELETE_COMMENT);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/party-members/getProfile', {
            userId: comment.user.id,
        }).then((res) => {
            if (res.data) {
                setProfile(res.data);
            }
        });
    }, []);

    const onClickCommentDelete = (commentId) => {
        if (window.confirm("선택한 댓글을 삭제하시겠습니까?")) {
            dispatch(loadingAction.loading(true));
            deleteComment({variables: { id: commentId }}).then(res => {
                dispatch(loadingAction.loading(false));
                onDelete();
            });
        }
    }

    return <_Comment>
        <Link to={"/user/" + comment.user.id}>
            <img src={profile?.profileImage?.url ?? '/defaultProfile2.png'} alt={`${profile?.nickname}의 프로필`} className="comment-profile-pic" />
        </Link>
        <div className="comment-content">
            <Link to={"/user/" + comment.user.id}>
                <strong>{profile?.nickname}</strong>
            </Link>
            <p>{comment.body}</p>
            <div className="comment-date">{moment.tz(comment.createdAt, 'Asia/Seoul').format('MM.DD HH:mm:ss')}</div>
        </div>
        {(tokenInfo?.id === comment.user.id || canDelete) && <button className="comment-delete" onClick={() => onClickCommentDelete(comment.id)}>X</button>}
    </_Comment>
}

const _Comment = styled.div`
  display: flex;
  align-items: center;
  background: #f3f3f3;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;

  .comment-profile-pic {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
  }

  .comment-content {
    flex: 1;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.6rem 0.6rem 0.1rem;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    word-wrap: break-word; /* 긴 단어가 영역을 벗어나지 않도록 함 */
  }

  .comment-date {
    margin-top: 0.2rem;
    font-size: 0.8em;
    color: #777;
    align-self: flex-end;
  }

  .comment-delete {
    position: absolute;
    top: 18px;
    right: 16px;
    background: transparent;
    font-size: 0.8em;
    color: #999;
    cursor: pointer;
    border: 1px solid black;
    padding: 2px 6px;
  }

  .comment-delete:hover {
    color: #e74c3c;
  }
`