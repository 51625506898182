import {Dialog} from "@mui/material";
import {toastAction} from "../../redux/toastReducer";
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {useMutation} from "@apollo/client";
import {GET_ME, UPDATE_USER} from "../../query/userQuery";
import {loadingAction} from "../../redux/loadingReducer";
import styled from "styled-components";
import {PrimaryButton} from "../components/CommonComponents";


export const InviteDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const [updateUser] = useMutation(UPDATE_USER);

    const onCopy = async () => {
        let inviteKey = userInfo.inviteKey;
        if (!inviteKey) {
            inviteKey = v4();
            console.log(inviteKey);
            const payload = {
                refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                variables: {
                    id: tokenInfo.id,
                    data: {
                        inviteKey,
                    }
                }
            };
            dispatch(loadingAction.loading(true));
            await updateUser(payload);
            dispatch(loadingAction.loading(false));
        }
        let text = `[우연한디너 초대장] 💌
FROM. ${userInfo.nickname}

새로운 사람들과의 우연한 저녁식사 어떤데?

${userInfo.nickname}님이 초대하는 우연한디너에 참석해보세요!

우연한디너는 매주 목요일 오후 7시, 서로 호감을 가질만한 4~6인이 함께 하는 디너모임를 제공합니다. 피드백 알고리즘으로 점점 차별화되는 나의 디너모임을 경험해보세요!

※ 아래 초대링크를 통해 가입 시에만 ${userInfo.nickname}님이 선물하는 1개월 무료 구독권이 지급됩니다. 🎁

https://woodinner.co.kr?inviteKey=${encodeURIComponent(inviteKey)}`
        // let text = "우연한디너는 서로 호감이 있을만한 사람들끼리의 디너모임을 제공하며, 개인의 선호에 맞게 모임의 구성이 점점 차별화됩니다.\n"
        //     + "아래 링크를 통해 가입 시 무료 참석권 3장이 지급됩니다.\n"
        //     + `https://woodinner.co.kr?inviteKey=${encodeURIComponent(inviteKey)}`;
        window.navigator.clipboard.writeText(text).then(() => {
            dispatch(toastAction.toast({severity: 'success', message: '링크가 복사되었습니다. 초대하고 싶은 친구에게 전달해보세요!'}));
        });
    }
    return <Dialog open={open} onClose={onClose}>
        <Container>
            <h3>심심해하는 친구에게 즐거운 만남을 선물하세요!</h3>
            <p>내 초대장을 통해 가입한 지인은 이벤트 참여로 3회 무료 참석이 가능하고, 나에게도 무료 참석권 1개가 추가로 지급됩니다. 🎁</p>
            <p>내가 초대한 지인이 모임 참석 후 '😃 좋았어요' 이상의 평가를 받을수록 나에게도 무료 참석권이 생겨요!</p>
            <p>아래 초대링크를 통해 가입 시에만 내가 초대한 지인으로 등록됩니다.</p>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PrimaryButton onClick={onCopy}>초대 링크 복사하기</PrimaryButton>
            </div>
        </Container>
    </Dialog>
}


const Container = styled.div`
  word-break: keep-all;
  font-family: "Elice Digital Baeum", sans-serif;
  padding: 16px;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  
  
`
