import styled from "styled-components";
import {_FormComponent} from "./FormComponent";

export const RadioComponentSimple = ({id, title, isColumn, form, onChange, values, ...props}) => {
    const checked = (v) => {
        if (form[id]) {
            return form[id] === v.id;
        }
        return false;
    }

    return <_RadioComponentSimple {...props}>
        {title && <div className="title">{title}</div>}
        <div className={`radio-input ${isColumn? 'is-column': ''}`}>
            {values.map((v) => <>
                <div key={id + v.id} className={`select-box --${checked(v)? 'checked': 'unchecked'}`} onClick={() => {
                    onChange({id, value: v.id})
                }}>
                    <img src={`/check_${checked(v)}.svg`} />
                    <div className="body">
                        <div className="title">{v.label}</div>
                    </div>
                </div>
            </>)}
        </div>
    </_RadioComponentSimple>
}

const _RadioComponentSimple = styled(_FormComponent)`

  > .small-title {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin: 4px 0;
  }
  > .title {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom: 4px;
  }
  
  //> .title {
  //  color: var(--gray-scale-black, #212121);
  //  font-feature-settings: 'clig' off, 'liga' off;
  //  font-size: 14px;
  //  font-style: normal;
  //  font-weight: 500;
  //  line-height: 22px;
  //}
  
  > .radio-input {
    margin-top: 4px;
    display: flex;
    gap: 20px;

    > .select-box {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--gray-scale-gray-800, #666);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      
      
      > .body {
        > .title {
          margin-left: 8px;
          color: var(--gray-scale-gray-800, #666);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
        }
      }
    }

    > .--checked {
      color: var(--gray-scale-black, #212121);
      font-weight: 600;
      > .body {
        > .title {
          color: var(--gray-scale-black, #212121);
        }
      }
    }
  }


  > .is-column {
    flex-direction: column;
    gap: 16px;
    > .select-box {
      justify-content: flex-start;
  }
`
