import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import qs from 'query-string';
import {kakaoRestLogin} from "../../toolbox/kakao";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {loadingAction} from "../../redux/loadingReducer";
import {authAction} from "../../redux/authReducer";
import {FormComponent} from "../components/FormComponent";
import {userAction} from "../../redux/userReducer";
import {parseJwt} from "../../toolbox/jwt";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import {useForm} from "../../toolbox/hook";
import {RadioComponentSimple} from "../components/RadioComponent";

const PageWrap = styled.div`
  font-family: "Elice Digital Baeum", sans-serif;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  max-width: 450px;
`

export function LoginCallbackPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const parsed = location.search ? qs.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const prev = parsed?.state ?? '/';

    const {form, onChange} = useForm({
        nickname: '',
        phone: '',
        birth: '',
        gender: '',
    });

    const [updateUser] = useMutation(UPDATE_USER);
    const [token, setToken] = useState(null);
    const [sended, setSended] = useState(false);

    useEffect(() => {
        if (parsed.code && !sended) {
            setSended(true);
            dispatch(loadingAction.loading(true));
            kakaoRestLogin(parsed.code, handleLogin)
        }
    }, [parsed.code]);

    useEffect(() => {
        dispatch(loadingAction.loading(false));
    }, [location]);

    const handleLogin = (jwt, userInfo) => {
        dispatch(loadingAction.loading(false));
        if (userInfo.phone && userInfo.gender && userInfo.birth) {
            dispatch(authAction.update(jwt))
            navigate(prev);
        } else {
            dispatch(authAction.logout());
            dispatch(userAction.logout());
            setToken(jwt);
            if (userInfo?.nickname) {
                onChange({id: 'nickname', value: userInfo.nickname});
            }
            if (userInfo?.phone) {
                onChange({id: 'phone', value: userInfo.phone});
            }
            if (userInfo?.birth) {
                onChange({id: 'birth', value: userInfo.birth});
            }
            if (userInfo?.gender) {
                onChange({id: 'gender', value: userInfo.gender});
            }
        }
    }

    const handleAgreement = async () => {
        const nickname = form.nickname;
        const phone = form.phone;
        const birth = form.birth;
        const gender = form.gender;
        if (!nickname) {
            alert('닉네임을 입력해주세요');
            return;
        }
        if (!phone) {
            alert('연락처를 입력해주세요');
            return;
        }
        if (!birth) {
            alert('생년월일을 입력해주세요');
            return;
        }
        if (!gender) {
            alert('성별을 선택해주세요');
            return;
        }

        dispatch(loadingAction.loading(true));
        dispatch(authAction.update(token))

        const payload = {
            variables: {
                id: parseJwt(token).id,
                data: {
                    nickname,
                    phone,
                    birth,
                    gender,
                }
            }
        };

        await updateUser(payload);
        try {
            window.fbq('track', 'CompleteRegistration');
            await axios.post(SERVER_ADDRESS + '/api/party-members/signup', {
                userId: parseJwt(token).id,
            }, {});
        } catch (e) {

        }
        dispatch(loadingAction.loading(false));
        navigate(prev, {replace: true});
    }

    return (<PageWrap>
        {token && <>
            <h3 style={{lineHeight: '46px'}}>필수 정보 입력</h3>
            <FormComponent title="닉네임" id="nickname" onChange={onChange} form={form} />
            <FormComponent title="휴대폰" id="phone" onChange={onChange} form={form} />
            <FormComponent type="date" title="생년월일" id="birth" onChange={onChange} form={form} />
            <RadioComponentSimple id="gender" title="성별" form={form} onChange={onChange} values={[
                {id: 'MALE', label: '남성'},
                {id: 'FEMALE', label: '여성'},
            ]}/>
            <div style={{display: 'flex', marginTop: '20px', justifyContent: 'center'}}>
                <div onClick={handleAgreement} style={{padding: '10px 20px', border: '1px solid black', display: 'flex'}}>완료하기</div>
            </div>
        </>}
        {/*<LoadingIndicator isVisible={true} />*/}
        {/*/!*{token ?*!/*/}
        {/*/!*    <div></div>:*!/*/}
        {/*/!*    <LoadingIndicator isVisible={true} />*!/*/}
        {/*/!*}*!/*/}
        {/*/!*<br/>*!/*/}
    </PageWrap>);
};